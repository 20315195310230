<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">

    <table class="table">
      <thead>
      <tr>
        <th class="text-left">
          product
        </th>
        <th class="text-left">
          status
        </th>
        <th class="text-left">
          quantity
        </th>
        <th class="text-left">
          created at
        </th>
        <th class="text-left">
          &nbsp;
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          class="align-middle"
          v-for="item in this.subscriptions"
          :key="item.id"
      >
        <td>{{ item.name }}</td>
        <td><span class="badge" :class="this.getStatusClass(item.status)">{{ this.subscriptionStatus(item.status) }}</span></td>
        <td>{{ item.quantity }}</td>
        <td>{{ formatDate(item.created_at) }}</td>
        <td>
          <div class="d-grid gap-2">
            <a v-if="this.isDigitalProduct(item)" :href="item.download_url" :class="{ disabled: null === item.download_url }" class="btn btn-outline-primary btn-sm" target="_blank">download</a>
            <template v-if="'stripe' === item.payment_provider">
              <a :href="this.customerPortalUrl(item.id)" class="btn btn-outline-primary btn-sm" target="_blank">manage subscription</a>
            </template>
            <template v-if="'paddle' === item.payment_provider">
              <a :href="item.update_url" class="btn btn-outline-primary btn-sm" :class="{ disabled: 2 === item.status }" target="_blank">update subscription</a>
              <a :href="item.cancel_url" class="btn btn-outline-danger btn-sm" :class="{ disabled: 2 === item.status }" target="_blank">cancel subscription</a>
            </template>
            <template v-if="'woocommerce' === item.payment_provider">
              <a @click="this.cancelSubscription(item.id)" class="btn btn-outline-danger btn-sm" :class="{ disabled: 2 === item.status }" target="_blank">cancel subscription</a>
            </template>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
</template>

<script>
import Swal from 'sweetalert2';
import { mapState } from 'vuex';
import {getEndpoint} from "@/utils/endpoints";
import moment from 'moment';
import subscriptionApi from '@/utils/api/subscription';

export default {
  name: 'SubscriptionsTable',
  computed: {
    ...mapState('subscriptions', [
      'subscriptions'
    ]),
    headers () {
      return [
        {
          text: 'id',
          value: 'id'
        },
        {
          text: 'status',
          value: 'status'
        },
        {
          text: 'name',
          value: 'name'
        },
        {
          text: 'quantity',
          value: 'quantity'
        },
        {
          text: 'created_at',
          value: 'created_at'
        }
      ];
    }
  },
  methods: {
    customerPortalUrl(subscriptionId) {
      return process.env.VUE_APP_API_URL + getEndpoint('customerPortal', { subscriptionId})
    },
    subscriptionStatus(subscriptionStatusId) {
      const statuses = {
        0: 'pending',
        1: 'active',
        2: 'canceled',
        3: 'incomplete',
        4: 'incomplete expired',
        5: 'past due',
        6: 'trialing',
        7: 'unpaid',
        8: 'paused'
      };

      return statuses[subscriptionStatusId] ?? subscriptionStatusId;
    },
    getStatusClass(subscriptionStatusId) {
      switch (subscriptionStatusId) {
        case 1:
          return 'bg-success';
        case 6:
          return 'bg-primary';
      }

      return 'bg-danger';
    },
    isDigitalProduct(item) {
      return 'digital_product' === item.type;
    },
    formatDate(dateString) {
      return moment(dateString).format('YYYY/MM/DD HH:mm:ss');
    },
    cancelSubscription(subscriptionId) {
      subscriptionApi.cancelSubscription(subscriptionId)
          .then(() => {
            Swal.fire({
              html: 'Your subscription has been successfully canceled.',
              icon: 'success',
              confirmButtonText: 'Close'
            });

          })
          .catch(() => {
            Swal.fire({
              html: 'Unable to cancel subscription, please contact us at <a href="mailto:contact@tagconcierge.com">contact@tagconcierge.com</a>.',
              icon: 'error',
              confirmButtonText: 'Close'
            });
          });
    }
  }
};
</script>

<style>
</style>
