import HttpService from '@/services/httpService';
import { getEndpoint } from '@/utils/endpoints';

export default {
    createServerContainer: (serverContainer) => {
        return HttpService.post(getEndpoint('serverContainers'), serverContainer);
    },
    deleteServerContainer: (serverContainer) => {
        return HttpService.delete(getEndpoint('serverContainers'), { data: serverContainer });
    },
    getServerContainers: () => {
        return HttpService.get(getEndpoint('serverContainers'));
    },
    getServerContainerStatus: (serverContainer) => {
        return HttpService.get(getEndpoint('serverContainerStatus'), { params: { id: serverContainer.id } });
    }
};
