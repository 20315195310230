<template>
  <section>
    <div class="row justify-content-md-center">
      <div class="col col-sm-8 p-4 shadow-sm rounded-3 rounded">
        <div class="row">
          <div class="col">
            <h2>Server Containers</h2>
            <ServerContainersTable @delete-container="openDeleteModal" @open-status-modal="openStatusModal"></ServerContainersTable>
          </div>
        </div>
        <div class="row justify-content-md-center">
          <div class="col col-md-auto">
            <button @click="openModal" class="btn btn-primary btn-sm">Create Server Container</button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <ServerContainerModal @close="closeModal" :isModalVisible="isModalVisible"></ServerContainerModal>

  <ServerContainerDeleteModal @close="closeDeleteModal" @delete-container-confirmed="deleteContainer" :containerToDelete="containerToDelete"></ServerContainerDeleteModal>

  <ServerContainerStatusModal @close="closeStatusModal" @status-container-confirmed="deleteContainer" :containerToStatus="containerToStatus"></ServerContainerStatusModal>
</template>

<script>
import { defineComponent } from 'vue';
import ServerContainersTable from '@/components/server-containers/ServerContainersTable';
import ServerContainerModal from '@/components/server-containers/ServerContainerModal';
import ServerContainerDeleteModal from '@/components/server-containers/ServerContainerDeleteModal';
import ServerContainerStatusModal from '@/components/server-containers/ServerContainerStatusModal';

export default defineComponent({
  name: 'ServerContainersView',
  components: {
    ServerContainersTable,
    ServerContainerModal,
    ServerContainerDeleteModal,
    ServerContainerStatusModal
  },
  data() {
    return {
      isModalVisible: false,
      containerToDelete: false,
      containerToStatus: false
    };
  },
  methods: {
    getServerContainers () {
      return this.$store.dispatch('serverContainers/getServerContainers');
    },
    openModal () {
      this.isModalVisible = true;
    },
    closeModal () {
      this.isModalVisible = false;
    },
    openDeleteModal(container) {
      this.containerToDelete = container;
    },
    closeDeleteModal() {
      this.containerToDelete = false;
    },
    openStatusModal(container) {
      this.containerToStatus = container;
    },
    closeStatusModal() {
      this.containerToStatus = false;
    },
    deleteContainer(container) {
      return this.$store.dispatch('serverContainers/deleteServerContainer', container);
    }
  },
  created () {
    this.getServerContainers();
  }
});
</script>
