<template>
  <v-form
      class=""
      ref="step-1"
      v-model="valid"
      v-on:submit.prevent
      lazy-validation
  >
    <div v-if="step === 1">
      <div class="mb-3">
        <label for="email" class="form-label">E-mail</label>
        <input
            id="email"
            class="form-control mb-2"
            type="email"
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            required
        />
        <div id="email-help" class="form-text">We will send you login code and link there.</div>
      </div>
      <button
          class="btn btn-primary"
          :disabled="!valid"
          color="success"
          @click="sendCode"
      >
        Send code
      </button>
    </div>
    <div v-else>
      <div class="mb-3">
        <label for="auth-code" class="form-label">Auth code</label>
        <input
            id="auth-code"
            class="form-control mb-2"
            v-model="authCode"
            :rules="authCodeRules"
            label="Auth code"
            required
        />
        <div id="email-help" class="form-text">Paste the code received via email or click the login link.</div>
      </div>
      <button
          class="btn btn-primary"
          :disabled="!valid"
          color="success"
          @click="login"
      >
        Submit
      </button>
    </div>
  </v-form>
</template>
<script>
import auth from '@/utils/api/auth';
import {mapFields} from "vuex-map-fields";

export default {
  name: 'LoginForm',
  data: () => ({
    step: 1,
    valid: true,
    email: '',
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    authCode: '',
    authCodeRules: [
      v => !!v || 'Auth code is required',
      v => /[0-9]{6}/.test(v) || 'Invalid auth code',
    ]
  }),
  computed: {
    ...mapFields('auth', [
      'authData'
    ])
  },
  methods: {
    sendCode() {
        auth.sendAuthCode(this.email).then(() => {
          this.step = 2;
        }).catch(() => {
          alert('error')
        })
    },
    login() {
      this.authData = {email: this.email, code: this.authCode};
      this.$store.dispatch('auth/login')
    },
  },
}
</script>
