import { createStore } from 'vuex'
import auth from '@/store/modules/auth'
import subscriptions from '@/store/modules/subscriptions'
import serverContainers from '@/store/modules/server-containers'
import gtmPresets from '@/store/modules/gtm-presets'

export default createStore({
  modules: {
    auth,
    subscriptions,
    serverContainers,
    gtmPresets
  }
})
