import subscriptionApi from '@/utils/api/subscription';

export default {
    namespaced: true,
    state: () => ({
        subscriptions: []
    }),
    mutations: {
        SET_SUBSCRIPTIONS (state, payload) {
            state.subscriptions = payload;
        }
    },
    actions: {
        getSubscriptions ({ commit }, id) {
            return subscriptionApi.getSubscriptions(id)
                .then(({ data }) => {
                    commit('SET_SUBSCRIPTIONS', data.map(el => ({
                        ...el,
                    })));
                });
        }
    }
};
