import { getField, updateField } from 'vuex-map-fields';
import authApi from '@/utils/api/auth';
import router from '@/router';

export default {
    namespaced: true,
    state: () => ({
        authData: {
            email: null,
            code: null,
            token: null,
        },
        isLogged: false,
        clientData: {},
    }),
    getters: {
        getField
    },
    mutations: {
        updateField,
        SET_IS_LOGGED (state, payload) {
            state.isLogged = payload;
        },
        SET_CLIENT_DATA (state, payload) {
            state.clientData = payload;
        },
        RESET_AUTH_DATA (state) {
            state.authData = {
                email: null,
                code: null,
                token: null,
            };
        }
    },
    actions: {
        login ({ state, commit }) {
            return authApi.login(state.authData)
                .then(({ data }) => {
                    commit('SET_IS_LOGGED', true);
                    commit('SET_CLIENT_DATA', data);
                    localStorage.setItem('isLogged', 'true');
                    localStorage.setItem('clientData', JSON.stringify(data))
                    router.push({ name: 'home' });
                });
        },
        logout ({ commit }) {
            return authApi.logout()
                .then(() => {
                    commit('SET_IS_LOGGED', false);
                    commit('SET_CLIENT_DATA', {});
                    commit('RESET_AUTH_DATA')
                    localStorage.setItem('isLogged', 'false');
                    router.push({ name: 'login' });
                });
        },
        checkAuthorisation ({ commit }) {
            const _isLogged = localStorage.getItem('isLogged') === 'true';
            commit('SET_IS_LOGGED', _isLogged);
            commit('SET_CLIENT_DATA', JSON.parse(localStorage.getItem('clientData')));
        }
    }
};
