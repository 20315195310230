<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="modal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Delete Server Container</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit="submitForm">
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">This operation will destroy container, you cannot undo this operation. The connected GTM workspace won't be affected and you can create new container with the same GTM configuration at any time. Type in container hostname to confirm delete:</label>
                <input v-model="confirmationHostname" v-bind:pattern="regexp" type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" required>
                <div id="emailHelp" class="form-text">Selected container: {{containerToDelete.custom_hostname}}</div>
              </div>
              <button type="submit" class="btn btn-danger">DELETE</button>
            </form>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap";

export default {
  name: 'ServerContainerDeleteModal',
  props: ['containerToDelete'],
  mounted() {
    this.modal = new Modal(this.$el);
    this.$el.addEventListener('hidden.bs.modal', () => {
      this.$emit('close');
    });
  },
  watch: {
    containerToDelete(val) {
        if (val !== false) {
            this.modal.show();
        } else {
          this.confirmationHostname = "";
          this.modal.hide();
        }
    }
  },
  computed: {
    regexp () {
      if (this.containerToDelete && this.containerToDelete) {
        return this.containerToDelete.custom_hostname.replace('.', '\\.');
      } else {
        return '';
      }
    },
  },
  data() {
    return {
      confirmationHostname: ""
    }
  },
  methods: {
    submitForm(ev) {
      ev.preventDefault();
      if (this.confirmationHostname === this.containerToDelete.custom_hostname) {
        this.$emit('deleteContainerConfirmed', this.containerToDelete);
        this.confirmationHostname = "";
        this.modal.hide();
      }
    }
  }
};
</script>

<style>
</style>
