<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">

    <table class="table">
      <thead>
      <tr>
        <th class="text-left">
          hostname
        </th>
        <th class="text-left">
          region
        </th>
        <th class="text-left">
          created at
        </th>
        <th class="text-left">
          &nbsp;
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          class="align-middle"
          v-for="item in this.serverContainers"
          :key="item.id"
      >
        <td>{{ item.custom_hostname }}</td>
        <td>{{ item.regions[0] }}</td>
        <td>{{ formatDate(item.created_at.date) }}</td>
        <td>
          <div class="d-grid gap-2">
            <button @click="openStatusModal(item)" class="btn btn-outline-primary btn-sm">setup</button>
            <button @click="openDeleteModal(item)" class="btn btn-outline-danger btn-sm">destroy</button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';

export default {
  name: 'ServerContainersTable',
  computed: {
    ...mapState('serverContainers', [
      'serverContainers'
    ]),
  },
  methods: {
    openDeleteModal(container) {
      this.$emit('deleteContainer', container);
    },
    openStatusModal(container) {
      this.$emit('openStatusModal', container);
    },
    formatDate(dateString) {
      return moment(dateString).format('YYYY/MM/DD HH:mm');
    }
  }
};
</script>

<style>
</style>
