<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="modal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Create New Server Container</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit="submitForm">
              <div class="mb-3">
                <label for="customHostname" class="form-label">Hostname</label>
                <input v-model="serverContainer.custom_hostname" type="text" class="form-control" id="customHostname" aria-describedby="emailHelp" placeholder="metrics.domain.com" required>
                <div id="emailHelp" class="form-text">Subdomain within your main domain</div>
              </div>
              <div class="mb-3">
                <label for="containerConfig" class="form-label">Container Configuration</label>
                <input v-model="serverContainer.container_config" type="password" class="form-control" id="containerConfig" required>
                <div id="containerConfigHelp" class="form-text">String from Google Tag Manager</div>
              </div>
              <fieldset class="row mb-3">
                <legend class="col-form-label col-sm-2 pt-0">Region</legend>
                <div class="col-sm-10">
                  <div class="form-check">
                    <input v-model="serverContainer.region" class="form-check-input" type="radio" name="region" id="regionEu" value="eu" required>
                    <label class="form-check-label" for="regionEu">
                      EU
                    </label>
                  </div>
                  <div class="form-check">
                    <input v-model="serverContainer.region" class="form-check-input" type="radio" name="region" id="regionUs" value="us" required>
                    <label class="form-check-label" for="regionUs">
                      US
                    </label>
                  </div>
                  <div class="form-check disabled">
                    <input v-model="serverContainer.region" class="form-check-input" type="radio" name="region" id="regionApac" value="asia" required>
                    <label class="form-check-label" for="regionApac">
                      APAC
                    </label>
                  </div>
                </div>
              </fieldset>
              <fieldset class="row mb-3">
                <legend class="col-form-label col-sm-2 pt-0">Size</legend>
                <div class="col-sm-10">
                  <div class="form-check">
                    <input v-model="serverContainer.size" class="form-check-input" type="radio" name="size" id="sizeSmall" value="2" required>
                    <label class="form-check-label" for="sizeSmall">
                      BASE ($15/month)
                    </label>
                  </div>
                  <!--<div class="form-check">
                    <input v-model="serverContainer.size" class="form-check-input" type="radio" name="size" id="sizeMedium" value="4">
                    <label class="form-check-label" for="sizeMedium">
                      Medium ($30/month)
                    </label>
                  </div>
                  <div class="form-check disabled">
                    <input v-model="serverContainer.size" class="form-check-input" type="radio" name="size" id="sizeLarge" value="8">
                    <label class="form-check-label" for="sizeLarge">
                      Large ($60/month)
                    </label>
                  </div>-->
                </div>
              </fieldset>
              <button :disabled="loading" type="submit" class="btn btn-primary">Create Container</button>
              <div v-if="loading" class="mt-3 alert alert-success" role="alert">
                Creating...
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap";

export default {
  name: 'ServerContainerModal',
  props: ['isModalVisible'],
  mounted() {
    this.modal = new Modal(this.$el);
    this.$el.addEventListener('hidden.bs.modal', () => {
      this.$emit('close');
    });
  },
  watch: {
    isModalVisible(val) {
        if (val === true) {
          this.modal.show();
        }
    }
  },
  data() {
    return {
      serverContainer: {
      },
      loading: false
    }
  },
  methods: {
    submitForm(ev) {
      ev.preventDefault()
      // turn on loading state
      this.loading = true;
      this.$store.dispatch('serverContainers/createServerContainer', this.serverContainer)
        .then(() => {
          this.loading = false
          this.modal.hide();
          this.serverContainer = {};
        });
    }
  }
};
</script>

<style>
</style>
