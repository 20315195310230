import serverContainerApi from '@/utils/api/server-containers';

export default {
    namespaced: true,
    state: () => ({
        serverContainers: [],
        serverContainerStatus: false
    }),
    mutations: {
        SET_SERVER_CONTAINERS (state, payload) {
            state.serverContainers = payload;
        },
        SET_SERVER_CONTAINER_STATUS (state, payload) {
            state.serverContainerStatus = payload;
        }
    },
    actions: {
        getServerContainers ({ commit }) {
            return serverContainerApi.getServerContainers()
                .then(({ data }) => {
                    commit('SET_SERVER_CONTAINERS', data);
                });
        },
        createServerContainer ({ commit, state }, serverContainer) {
          return serverContainerApi.createServerContainer(serverContainer)
              .then(({ data } ) => {
                  commit('SET_SERVER_CONTAINERS', [...state.serverContainers, data]);
              });
          },
        deleteServerContainer({ commit, state }, serverContainer) { // eslint-disable-line no-unused-vars
            return serverContainerApi.deleteServerContainer(serverContainer)
                .then(() => {
                    return serverContainerApi.getServerContainers()
                        .then(({ data }) => {
                            commit('SET_SERVER_CONTAINERS', data);
                        });
                })
        },
        getServerContainerStatus({ commit }, serverContainer) {
            return serverContainerApi.getServerContainerStatus(serverContainer)
                .then(({ data }) => {
                    commit('SET_SERVER_CONTAINER_STATUS', data);
                });
        },
    }
};
