import store from '@/store';
import router from '@/router';

export function onFulfilled (response) {
    return response;
}

export function onRejected (error) {
    if (error.request.status === 401 && router.currentRoute.name === 'login') {
        store.commit('error/SET_ERROR', {
            status: error.request.status,
            text: JSON.parse(error.request.responseText).message,
            title: null
        });
    } else if (error.request.status === 401) {
        store.commit('error/RESET_ERROR');
        store.dispatch('auth/logout');
        router.push({ name: 'login' });
    } else {
        store.commit('error/SET_ERROR', {
            status: error.request.status,
            text: JSON.parse(error.request.responseText).message,
            title: null
        });
    }
    return Promise.reject(error);
}
