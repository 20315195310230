<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="modal" tabindex="-1">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Server Container Setup</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body p-0">
            <div class="accordion accordion-flush" id="accordionFlushExample">
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                    DNS Setup <span v-if="serverContainerStatus === false" class="ms-1 badge bg-secondary">Checking...</span><span v-if="serverContainerStatus !== false"><span v-if="serverContainerStatus?.status?.custom_hostname_raw !== 'active'" class="ms-1 badge bg-info">Pending</span><span v-if="serverContainerStatus?.status?.custom_hostname_raw === 'active'" class="ms-1 badge bg-success">Verified</span></span>
                  </button>
                </h2>
                <div id="flush-collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">RECORD</th>
                          <th scope="col">HOSTNAME</th>
                          <th scope="col">TARGET</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>CNAME</td>
                          <td>{{containerToStatus.custom_hostname}}</td>
                          <td>{{containerToStatus?.cluster?.root_hostname}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseTwo">
                    GTM Presets
                  </button>
                </h2>
                <div id="flush-collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">

                    <div class="row row-cols-1 row-cols-md-2 g-4">
                      <div class="col">
                        <div class="card">
                          <div class="card-body">
                            <h5 class="card-title">Google Tag to Server (Advanced)</h5>
                            <p class="card-text">Send all eCommerce events to Server Container. Requires configured GTM server container, learn more in docs</p>
                            <a  href="https://docs.tagconcierge.com/article/61-how-to-send-events-to-gtm-server-container-with-google-tags-preset" target="_blank" class="card-link">Documentation</a>
                            <a href="#" @click="downloadGtmPreset({ id: 'presets/gtag-to-server-advanced' })" class="card-link">Download</a>
                          </div>
                        </div>
                      </div>
                      <div
                          class="col"
                          v-for="item in this.gtmPresets"
                          :key="item.id"
                      >
                        <div class="card">
                          <div class="card-body">
                            <h5 class="card-title">{{item.name}}</h5>
                            <p class="card-text">{{item.description}}</p>
                            <a v-if="item.documentation" :href="item.documentation" target="_blank" class="card-link">Documentation</a>
                            <a href="#" @click="downloadGtmPreset(item)" class="card-link">Download</a>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                    GTM Snippets
                  </button>
                </h2>
                <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">
                    <p>Provide GTM Container ID of GTM Web container that is served from your server GTM</p>
                    <p>
                      <a href="https://docs.tagconcierge.com/article/62-how-to-serve-gtm-web-container-from-server-container" target="_blank">Documentation</a><br />
                      <a href="#" @click="downloadGtmPreset({ id: 'presets\/gtag-to-server-advanced' })" class="card-link">Download GTM Preset</a>
                    </p>
                    <input type="text" placeholder="
GTM-ABCD1234" v-model="webContainerId" class="mb-3"/>
                    <p>Then copy the code below and paste it onto every page of your website.</p>
                    <div class="mb-3">
                      <label for="snippetHead" class="form-label">1. Paste this code as high in the <strong>head</strong> of the page as possible:</label>
                      <textarea class="form-control form-control-sm" id="snippetHead" rows="3" v-model="snippetHead"></textarea>
                    </div>
                    <div class="mb-3">
                      <label for="snippetBody" class="form-label">2. Paste this code immediately after the opening <strong>body</strong> tag:</label>
                      <textarea class="form-control form-control-sm" id="snippetBody" rows="3" v-model="snippetBody"></textarea>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { Modal } from "bootstrap";
import {getEndpoint} from "@/utils/endpoints";

export default {
  name: 'ServerContainerStatusModal',
  props: ['containerToStatus'],
  mounted() {
    this.modal = new Modal(this.$el);
    this.$el.addEventListener('hidden.bs.modal', () => {
      this.$emit('close');
    });
  },
  created () {
    this.getGtmPresets();
  },
  watch: {
    containerToStatus(val) {
        if (val !== false) {
          this.$store.dispatch('serverContainers/getServerContainerStatus', this.containerToStatus)
            // .then(() => {
              return this.modal.show();
            // });

        } else {
          this.modal.hide();
        }
    }
  },
  computed: {
    ...mapState('serverContainers', [
      'serverContainerStatus'
    ]),
    ...mapState('gtmPresets', [
      'gtmPresets'
    ]),
    snippetBody () {
      if (!/GTM-\w{8,9}/.test(this.webContainerId)) {
        return 'Set Web GTM Container ID above';
      }
      return `<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://`+this.containerToStatus.custom_hostname+`/ns.html?id=`+this.webContainerId+`"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->`
    },
    snippetHead () {
      if (!/GTM-\w{8,9}/.test(this.webContainerId)) {
        return 'Set Web GTM Container ID above';
      }
      return `<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://` + this.containerToStatus.custom_hostname + `/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','`+this.webContainerId+`');<`+'/'+`script>
<!-- End Google Tag Manager -->`;
    }
  },
  data() {
    return {
      confirmationHostname: "",
      webContainerId: null
    }
  },
  methods: {
    downloadGtmPreset(gtmPreset) {
      return fetch(process.env.VUE_APP_API_URL + getEndpoint('postGtmPreset', null, '/v2'), {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({ preset: gtmPreset.id }),
      }).then(async function(response) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = gtmPreset.id.replace('presets/', '') + ".json";
        a.click();
      });
    },
    submitForm(ev) {
      ev.preventDefault();
      if (this.confirmationHostname === this.containerToDelete.custom_hostname) {
        this.$emit('deleteContainerConfirmed', this.containerToDelete);
        this.confirmationHostname = "";
        this.modal.hide();
      }
    },
    getGtmPresets () {
      return this.$store.dispatch('gtmPresets/getGtmPresets');
    }
  }
};
</script>

<style>
</style>
