import HttpService from '@/services/httpService';
import { getEndpoint } from '@/utils/endpoints';

export default {
    getSubscriptions: () => {
        return HttpService.get(getEndpoint('subscriptions'));
    },
    cancelSubscription: (subscriptionId) => {
        return HttpService.delete(getEndpoint('cancelSubscription', {subscriptionId}));
    }
};
