<template>
  <div class="row justify-content-md-center g-0">
    <div class="col col-sm-4">
      <div style="text-align: center;">
        <h1 style="text-align: center">ANNOUNCEMENT</h1>
        <div>
          Tag Concierge Platform BETA is currently phased out as we continue to work on another generation of the tool.
        </div>
        <br>
        <div>
          In the meantime we ensure best possible experience when using our tracking&nbsp;plugins:
          <div>
            <ul>
              <li><a href="https://tagconcierge.com/google-tag-manager-for-woocommerce" target="_blank">Google Tag Manager for WooCommerce</a></li>
              <li><a href="https://tagconcierge.com/tag-concierge-for-prestashop" target="_blank">Tag Concierge for Prestashop</a></li>
            </ul>
          </div>
        </div>
        <br>
        <div>
          This includes but is not limited to:
          <div>
            <ul>
              <li>more robust web tracking</li>
              <li>additional advanced GTM presets</li>
              <li>better compatibility with top plugins and themes</li>
              <li>server-side tracking</li>
            </ul>
          </div>
        </div>
        <br>
        <hr>
        <br>
        <div>
          Our work on Tag Concierge Platform also continues and we will get back with more advanced and polished solutions soon.
          The new version will be fully compatible with GTM server-side and will support more complex setups.
        </div>
        <div>
          If you used our BETA tools or have any comments or interest in the above please reach us out here:
        </div>
        <br>
        <div>
          <a href="mailto:contact@tagconcierge.com" class="btn btn-outline-primary btn-sm" target="_blank">CONTACT</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BetaAnnouncement',
};
</script>

<style lang="scss">
  h1 {
    margin-bottom: .7em !important;
  }
  ul {
    margin-top: .5em !important;
    display: inline-block;
    width: 75%;
    li {
      text-align: left !important;
    }
  }
</style>
