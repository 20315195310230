import axios from 'axios';
import { onFulfilled, onRejected } from '@/services/interceptor';

export class HttpService {
    constructor () {
        this.axios = axios.create({
            baseURL: process.env.VUE_APP_API_URL,
            headers: { 'Content-type': 'application/json;charset=UTF-8' },
            withCredentials: true
        });

        this.axios.interceptors.response.use(onFulfilled, onRejected);
    }

    get (url, params) {
        return this.axios.get(url, params);
    }

    post (url, data, params = null) {
        return this.axios.post(url, data, params);
    }

    put (url, data) {
        return this.axios.put(url, data);
    }

    patch (url, data, params = null) {
        return this.axios.patch(url, data, params);
    }

    delete (url, params) {
        return this.axios.delete(url, params);
    }

    all (iterable) {
        return axios.all(iterable);
    }

    spread (callback) {
        return axios.spread(callback);
    }
}

export default new HttpService();
