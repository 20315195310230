<template>
  <div class="app">
    <div class="main">
      <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
        <div class="container-fluid">
          <span class="navbar-brand mb-0 h1">Tag Concierge</span>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="/v2/">Subscriptions</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/v2/server-containers">Server Containers</a>
              </li>
            </ul>
            <div class="d-flex">
              <a v-if="this.isLogged" class="nav-link" href="#" @click="logout">logout ({{ this.clientData.email }})</a>
            </div>
          </div>
        </div>
      </nav>

      <div class="mt-5 container">


        <div class="row g-0">
          <div class="col">
            <router-view/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'App',
  computed: {
    ...mapState('auth', [
      'clientData',
        'isLogged'
    ]),
  },
  methods: {
    logout () {
      this.$store.dispatch('auth/logout');
    }
  },
}
</script>

<style type="text/css">

  .nav-underline a {
    color: #9DA2AB !important;
  }

  .nav-underline a.active {
    color: #0D324D !important;
  }

  .navbar.navbar-dark {
    background-color: #0D324D !important;
  }

  .navbar-dark .nav-link {
    color:  #ffffff;
  }


  .nav-scroller {
    position: relative;
    z-index: 2;
    height: 2.75rem;
    overflow-y: hidden;
  }

  .nav-scroller .nav {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    color: rgba(255, 255, 255, .75);
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
</style>
