import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView'
import LoginView from '@/views/LoginView'
import ServerContainersView from '@/views/ServerContainersView'
import store from '@/store';
import BetaAnnouncement from "@/views/BetaAnnouncement.vue";

const routes = [
  {
    path: '/v2',
    name: 'home',
    component: HomeView
  },
  {
    path: '/v2/server-containers',
    name: 'server-containers',
    component: ServerContainersView
  },
  {
    path: '/v2/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/announcement',
    name: 'beta-announcement',
    component: BetaAnnouncement
  }
]

const router = createRouter({
    mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  store.dispatch('auth/checkAuthorisation')
  .then(() => {
    if (to.name === 'beta-announcement') {
      next();
      return;
    }

    if (!to.fullPath.match('^/v2') && to.name !== 'beta-announcement') {
      next({ name: 'beta-announcement' })
    } else if (!store.state.auth.isLogged && to.name !== 'login') {
      next({ name: 'login' });
    } else if (store.state.auth.isLogged && to.name === 'login') {
      next({ name: 'home' });
    } else {
      next();
    }
  });
});

export default router
