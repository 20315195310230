import HttpService from '@/services/httpService';
import { getEndpoint } from '@/utils/endpoints';

export default {
    sendAuthCode: (email) => {
        return HttpService.post(getEndpoint('sendAuthCode'), {
            'email': email
        });
    },
    login: ({email, code, token}) => {
        const payload = null !== token && undefined !== token ? { token } : { email, code };

        return HttpService.post(getEndpoint('auth'), payload);
    },
    logout: () => {
        return HttpService.post(getEndpoint('logout'));
    }
};
