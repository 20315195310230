<template>
  <section>
    <div class="row justify-content-md-center">
      <div class="col col-sm-8 p-4 shadow-sm rounded-3 rounded">
        <h2>Your subscriptions</h2>
        <SubscriptionsTable></SubscriptionsTable>
      </div>
    </div>
  </section>

  <section>
    <div class="mt-5 row justify-content-md-center">
      <div class="col col-sm-8 p-4 shadow-sm rounded-3 rounded">
        <h2>Support</h2>
         <div class="row justify-content-md-center">
          <div class="col-md-auto">
            <a href="https://docs.tagconcierge.com/" class="btn btn-primary btn-sm" target="_blank">Documentation</a>
          </div>
          <div v-if="this.hasProductSubscription('Google Tag Manager for WooCommerce PRO')" class="col-md-auto">
            <a href="https://docs.tagconcierge.com/article/33-how-to-install-and-activate-the-woocommerce-plugin" class="btn btn-primary btn-sm" target="_blank">Get Started for WooCommerce</a>
          </div>
           <div v-if="this.hasProductSubscription('GTM Server Hosting')" class="col-md-auto">
             <a href="https://docs.tagconcierge.com/article/60-how-to-setup-gtm-server-container-hosting" class="btn btn-primary btn-sm" target="_blank">Get Started for GTM Server Hosting</a>
           </div>
          <div class="col-md-auto">
            <a href="https://tagconcierge.com/contact" class="btn btn-primary btn-sm" target="_blank">Contact</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import SubscriptionsTable from '@/components/subscriptions/SubscriptionsTable';
import {mapState} from "vuex";

export default defineComponent({
  name: 'HomeView',
  computed: {
    ...mapState('subscriptions', [
      'subscriptions'
    ]),
  },
  components: {
    SubscriptionsTable
  },
  methods: {
    getSubscriptions () {
      return this.$store.dispatch('subscriptions/getSubscriptions');
    },
    hasProductSubscription (productName) {
      return this.subscriptions.some(subscription =>
          subscription.name && subscription.name.toLowerCase().includes(productName.toLowerCase())
      );
    }
  },
  created () {
    this.getSubscriptions();
  }
});
</script>
