const prefix = '/v3';

const labels = {
    'auth': '/auth',
    'sendAuthCode': '/public/auth/send_code',
    'logout': '/auth/logout',
    'subscriptions': '/subscriptions',
    'cancelSubscription': '/subscriptions/:subscriptionId/cancel',
    'serverContainers': '/server-containers',
    'serverContainerStatus': '/server-containers/status',
    'customerPortal': '/stripe/customer-portal/:subscriptionId',
    'getGtmPresets': '/presets?container=server',
    'postGtmPreset': '/preset'
};

export const getEndpoint = (label, params = null, selectedPrefix = prefix) => {
    let url = `${selectedPrefix}${labels[label]}`;

    if (!params) {
        return url;
    }

    if (labels[label].indexOf(':') === -1) {
        return url;
    }

    if (typeof (params) === 'object') {
        for (const param in params) {
            url = url.replace(':' + param, params[param]);
        }
    }
    return url;
};
