import gtmPresetsApi from '@/utils/api/gtm-presets';

export default {
    namespaced: true,
    state: () => ({
        gtmPresets: [],
    }),
    mutations: {
        SET_GTM_PRESETS (state, payload) {
            state.gtmPresets = payload;
        },
    },
    actions: {
        getGtmPresets ({ commit }) {
            return gtmPresetsApi.getGtmPresets()
                .then(({ data }) => {
                    commit('SET_GTM_PRESETS', data);
                });
        }
    }
};
