<template>
  <div class="row justify-content-md-center">
    <div class="col col-sm-4">
      <h2>Login</h2>
      <div v-if="null !== this.authData.token">
        auth...
      </div>
      <LoginForm v-else/>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import LoginForm from "@/components/LoginForm";

export default {
  name: 'LoginView',
  components: {
    LoginForm,
  },
  data: () => ({
    loader: false
  }),
  computed: {
    ...mapFields('auth', [
      'authData'
    ])
  },
  mounted () {
    const urlParams = new URLSearchParams(window.location.search);
    this.authData.token = urlParams.get('token');
    if (this.authData.token === null) {
      return;
    }
    this.$store.dispatch('auth/login');
  }
};
</script>
